"use strict";






//    #####  ####  ######        ######  #####  ## ## ######
//    ##      ##     ##            ##    ##     ## ##   ##
//    ##      ##     ##            ##    ##     #####   ##
//    ####    ##     ##            ##    ####    ###    ##
//    ##      ##     ##            ##    ##     #####   ##
//    ##      ##     ##            ##    ##     ## ##   ##
//    ##     ####    ##            ##    #####  ## ##   ##
//
//
// textFit(document.getElementsByClassName('header--large2'),
//   minFontSize:10, maxFontSize: 150}
//   )
//
//    ####    ###   ####          ## ##  ####
//    ## ##  ## ##  ## ##         ## ##  ## ##
//    ## ##  ## ##  ## ##         ## ##  ## ##
//    ####   ## ##  ####   #####  ## ##  ####
//    ##     ## ##  ##            ## ##  ##
//    ##     ## ##  ##            ## ##  ##
//    ##      ###   ##             ###   ##
//
//
    // $(document).ready(function() {
    //     $('.galeria').Chocolat({
    //         imageSelector: '.popup-image',
        
    //     });
    // });
    
    window.onload=function(){
    
//  Get DOM Elements

        //modal
        const body = document.querySelector('html');
        const modal = document.querySelector('#person-modal');
        const modalName = document.querySelector('.person-modal__name');
        const modalImg = document.querySelector('.person-modal__image');
        const modalBio = document.querySelector('.person-modal__bio');
        const closeBtn = document.querySelector('.person-modal__close');

       //person
        const person = document.querySelectorAll('.about__person');
        const personName = document.querySelector('.about__person__image-caption');
        const personImg = document.querySelector('.about__person__image');
        const personBio = document.querySelector('.about__person__bio');
        

        // Events
        person.forEach(function(elem) {elem.addEventListener('click', openModal); });
        // person.addEventListener('click', openModal);
        modal.addEventListener('click', closeModal);
        window.addEventListener('click', outsideClick);
        document.addEventListener('keydown', function(event) {
            const key = event.key; // const {key} = event; in ES6+
            if (key === "Escape") {
                closeModal();
            }
        });

        // Open
        function openModal(e) {
            // modal.style.display = 'block';
          if(this.querySelector(".about__person__bio").innerHTML !== ''){
            body.classList.add("person-modal--active");
            body.classList.remove("person-modal--no-active");
            modal.classList.add("person-modal--opened");
            modal.classList.remove("person-modal--closed");

            modalName.innerHTML=this.querySelector(".about__person__image-caption").innerHTML;
            modalImg.src=this.querySelector(".about__person__image").src;
            modalBio.innerHTML=this.querySelector(".about__person__bio").innerHTML;


            console.log(this.querySelector(".about__person__image-caption").innerHTML);
          }
        }

        // Close
        function closeModal() {
            body.classList.remove("person-modal--active");
            body.classList.add("person-modal--no-active");
            modal.classList.remove("person-modal--opened");
            modal.classList.add("person-modal--closed");
            
        }

        // Close If Outside Click
        function outsideClick(e) {
            if (e.target == modal) {
                body.classList.remove("person-modal--active");
                body.classList.add("person-modal--no-active");
                modal.classList.remove("person-modal--opened");
                modal.classList.add("person-modal--closed");
            }
        }
       
    }

//
//   #   #  #####  #  ##  ## ##
//   ## ##  ##     ## ##  ## ##
//   #####  ##     #####  ## ##
//   #####  ####   #####  ## ##
//   #####  ##     #####  ## ##
//   ## ##  ##     ## ##  ## ##
//   ## ##  #####  ##  #   ###
//
//
$(document).ready(function() {
    $('.hamburger').click(function(e) {
        $('#top-header').toggleClass('is-open');

        $(this).toggleClass('is-active');
        e.preventDefault();
 
    });
    $('.has-children:not(.hide-sub-menu) > a').click(function(e) {
        $(this).next().toggleClass('is-open');

        // e.preventDefault();
        
    });
    $(".has-children > a").click(function(e) {
         if (window.matchMedia("(max-width: 900px)").matches) {
             e.preventDefault();
         }
    });
    
    //kiedy jeden drop down jest aktywny i ma wyjechać drugi:
    
        $(".has-children:not(.hide-sub-menu):not(.hide-sub-menu-large)").children("a").on({
                  "mouseenter" : function(e){
                    if (window.matchMedia("(min-width: 901px)").matches) {
                       $(".nav__sub-menu").not($(this).next('.nav__sub-menu')).children("li").css({'transform': 'translate(0,-100%)'});
                    }
                  },
                  "mouseleave" : function(e){
                    if (window.matchMedia("(min-width: 901px)").matches) {
                      $(".nav__sub-menu").not($(this).next('.nav__sub-menu')).children("li").css({'transform': 'translate(0,0)'});          
                    }    
                }
      });
   

    //lepiące menu do top

    $(window).bind('scroll', function() {
        if (window.matchMedia("(min-width: 901px)").matches) {
           if ($(window).scrollTop() > 100){
            $('.active.show-sub-menu-on-subpages .nav__sub-menu').addClass('nav-down');
             }
            else{
                $('.active.show-sub-menu-on-subpages .nav__sub-menu').removeClass('nav-down');
            }
        } 
       
    });
    // Hide Header on on scroll down
    var lastScrollTop = 0;
    var delta = 100;
    var navbarHeight = $('.active .nav__sub-menu').outerHeight();
    $(window).scroll(function(event) {
        if (window.matchMedia("(min-width: 901px)").matches) {
            var st = $(this).scrollTop();
            // console.log(st);
            // Make sure they scroll more than delta
            if (Math.abs(lastScrollTop - st) <= delta)
                return;
            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st > lastScrollTop && st > navbarHeight) {
                // Scroll Down
                $('.active.show-sub-menu-on-subpages .nav__sub-menu').removeClass('nav-down').addClass('nav-up');
            } else {
                // Scroll Up
                if (st + $(window).height() < $(document).height()) {
                    $('.active.show-sub-menu-on-subpages .nav__sub-menu').removeClass('nav-up');
                }
            }
            lastScrollTop = st;
        }
    });
});

//                                                    ###
//    ###   ## ##   ###                                ##
//   ## ##  ## ##  ## ##                               ##
//   ##     ## ##  ##            # ###   ###   ####    ##     ####   ####   ###
//    ###   ## ##  #####         ###    ## ##  ## ##   ##    ## ##  ##     ## ##
//      ##  ## ##  ## ##         ##     #####  ## ##   ##    ## ##  ##     #####
//   ## ##   ###   ## ##         ##     ##     ## ##   ##    ## ##  ##     ##
//    ###     #     ####         ##      ###   ####   ####    ## #   ####   ###
//                                             ##
//                                             ##
jQuery('img.svg').each(function() {
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');
    jQuery.get(imgURL, function(data) {
        // Get the SVG tag, ignore the rest
        var $svg = jQuery(data).find('svg');
        // Add replaced image's ID to the new SVG
        if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
        }
        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');
        // Replace image with new SVG
        $img.replaceWith($svg);
    }, 'xml');
});


//
//     ###    ###    ###   ## ##  ####   #####
//    ## ##  ## ##  ## ##  ## ##   ##    ##
//    ##     ## ##  ## ##  ####    ##    ##
//    ##     ## ##  ## ##  ###     ##    ####
//    ##     ## ##  ## ##  ####    ##    ##
//    ## ##  ## ##  ## ##  ## ##   ##    ##
//     ###    ###    ###   ## ##  ####   #####
//
//

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
}




//
//   ## ##  #####  ##     ####   #####  ####    ###
//   ## ##  ##     ##     ## ##  ##     ## ##  ## ##
//   ## ##  ##     ##     ## ##  ##     ## ##  ##
//   #####  ####   ##     ####   ####   ####    ###
//   ## ##  ##     ##     ##     ##     ###       ##
//   ## ##  ##     ##     ##     ##     ####   ## ##
//   ## ##  #####  #####  ##     #####  ## ##   ###
//
//
jQuery(document).ready(function($) {
    //  function getValue(){
    //    var value= $.ajax({ 
    //       url: './themes/o11e/env-config.json', 
    //       async: false
    //    }).responseText;
    //    return value;
    // }

    // var puk = getValue();
    // console.log(puk);
    // var mydata = JSON.parse(puk);
    // console.log("puk");
    // console.log(mydata.puk);
    //  var DEBUG = mydata.outline_helper;
     var DEBUG = false;
   
        // szuka pozycji elementu
        function getOffset(el) {
          var rect = el.getBoundingClientRect();
          return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
          };
        }

    if (DEBUG==true) {
        var p = '<div id="czujnik" class="czujnik" style="position:fixed; right:0; bottom:0; font-size:9px; background:white;"><input id="colors" type="checkbox"/> <span id="debug-width" ></span>x<span id="debug-height" ></span></div>';
        $('body').append(p);
        //wyświetla szerokość i wysokość okna
        $(window).resize(function() {
            $('#debug-height').html($(window).height());
            $('#debug-width').html($(window).width());
        }).resize();

        var ciacho = getCookie('obrysuj');
        if (ciacho == "tak") {
            $('input#colors').prop('checked', true);
        }

        var obrysuj = function() {
            if ($('input#colors').is(':checked')) {
                $('body *').each(function(index) {
                    var colors = ['red', 'blue', 'black', 'green', 'pink', 'orange', 'brown'];
                    var random_color = colors[Math.floor(Math.random() * colors.length)];
                    var box_shadow = '0 0 0 1px ' + random_color + ' ';
                    var tag_name = $(this).prop("tagName");
                    var class_name = $(this).attr("class");
                    var el_position = getOffset(this);

                    if ($(this).css('-webkit-box-shadow') == 'none') {
                        $(this).css('-webkit-box-shadow', box_shadow);
                          console.log("pozycja");
                    console.log(el_position);
                    console.log(el_position.top);
                         // to z pozycją ale jakos nie działa $(this).append('<div id="helper_tag_name" style="display: none; background:'+random_color+'; content:\''+ tag_name +'\'; position: absolute; top:'+el_position.top+'px; left:'+el_position.left+'px; color:white; font-size:9px; font-family: monospace; z-index:2222;">'+tag_name+'.'+class_name+'</div>');
                         $(this).append('<div id="helper_tag_name" style="display: none; background:'+random_color+'; content:\''+ tag_name +'\'; position: absolute; color:white; font-size:9px; font-family: monospace; z-index:2222;">'+tag_name+'.'+class_name+'</div>');
                         $(this).hover(function(){
                               
                                $(this).children('#helper_tag_name').css("display", "block");
                    
                                }, function(){
                                $(this).children('#helper_tag_name').css("display", "none");
                            });
                    }
                });
            } else {
                $('body *').each(function() {
                    $(this).remove('#helper_tag_name');
                    if ($(this).css('-webkit-box-shadow') !== 'none') {
                        $(this).css('-webkit-box-shadow', '');
                    }
                });
            }
        };
        obrysuj();

        //orbysowuje randomowym kolorem i trzyma w ciastku
        $('input#colors').change(function() {
            obrysuj();
            if ($('input#colors').is(':checked')) {

                setCookie('obrysuj', 'tak', 365);
            } else {
                setCookie('obrysuj', 'nie', 365);

            }

        });

    }
    // adds ios class to html tag
    var deviceAgent = navigator.userAgent.toLowerCase();
    var agentID = deviceAgent.match(/(iphone|ipod|ipad)/);
    if (agentID) {
        $('html').addClass('ios');
    }
    //adds touch-screen to html if device
    // if (Modernizr.touch) {
    //     $('html').addClass('touch-screen');
    // } else {
    //     $('html').addClass('no-touch-screen');
    // }
}); //end ready





//
//     #     ###    ###
//    ###   ## ##  ## ##
//   ## ##  ## ##  ##
//   ## ##  ## ##   ###
//   #####  ## ##     ##
//   ## ##  ## ##  ## ##
//   ## ##   ###    ###
//
//
AOS.init();

//
//       ###   ## ##  ####    ###   ##     ##     ####
//      ## ##  ## ##  ## ##  ## ##  ##     ##     ## ##
//      ##     ####   ## ##  ## ##  ##     ##     ## ##
//       ###   ###    ####   ## ##  ##     ##     ####
//         ##  ####   ###    ## ##  ##     ##     ###
//      ## ##  ## ##  ####   ## ##  ##     ##     ####
//       ###   ## ##  ## ##   ###   #####  #####  ## ##
//
//

// SKROLLR
    var s = skrollr.init({
        forceHeight: false
      });
    if (s.isMobile()) {
        s.destroy();
    }




//
//      #####    #     ###
//      ##      ###   ## ##
//      ##     ## ##  ## ##
//      ####   ## ##  ## ##
//      ##     #####  ## ##
//      ##     ## ##  ## ##
//      ##     ## ##   ###
//                       ##
//
// akoedeonik
$(document).ready(function() {
console.log('log');
console.log($('.faq__pytanie-odp').find('p').height());
    $('.faq__pytanie > h2').click(function(e) {
        $(this).toggleClass('is-active');
        $(this).siblings('.faq__pytanie-odp').slideToggle('fast','swing');
       
        // $('.faq__pytanie-odp').height('300px');
        

        
        e.preventDefault();
    });
});
    // $('.has-children > a').click(function(e) {
    //     $('.nav__sub-menu').toggleClass('is-open');
    //     e.preventDefault();
    // });


    //
    //       ###    ###   ####    ###   ##     ##           ######   ###
    //      ## ##  ## ##  ## ##  ## ##  ##     ##             ##    ## ##
    //      ##     ##     ## ##  ## ##  ##     ##             ##    ## ##
    //       ###   ##     ####   ## ##  ##     ##             ##    ## ##
    //         ##  ##     ###    ## ##  ##     ##             ##    ## ##
    //      ## ##  ## ##  ####   ## ##  ##     ##             ##    ## ##
    //       ###    ###   ## ##   ###   #####  #####          ##     ###
    //
    //

//ogolny

$(document).ready(function() {
    jQuery('.scroll-to').click(function(e){
        var jump = $(this).attr('href');
        var new_position = $(jump).offset();
        $('html, body').stop().animate({ scrollTop: new_position.top }, 500);
        e.preventDefault();
    });
});

///
// 
//
//      V I D E O 
//
//
//

$('.video-wrapper').click(function () {
    if($(this).children("video").get(0).paused){
        $(this).children("video").get(0).play();
        $(this).children(".playpause").fadeOut();
    }else{
       $(this).children("video").get(0).pause();
        $(this).children(".playpause").fadeIn();
    }
});


//
//  L I G H T B O X
//

var lightboxDescription = GLightbox({
    selector: '.lightbox'
});




